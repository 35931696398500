import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import MainLayout from './MainLayout';
import ListGroup from 'react-bootstrap/ListGroup';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Form from 'react-bootstrap/Form';
import { Helmet } from 'react-helmet-async';
import Spinner from 'react-bootstrap/Spinner';
import Toast from 'react-bootstrap/Toast';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti';
import { Watch } from 'react-loader-spinner';
import { IoMdSave } from 'react-icons/io';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { IoPerson } from 'react-icons/io5';
import FloatingGoBackButton from './FloatingGoBackButton';
import FloatingSaveButton from './FloatingSaveButton';
import './GroupCycleReview.css';
import SideNav from './SideNav';
import CalendarPopup from './CalendarPopup';
import { LuCalendarClock } from 'react-icons/lu';
import { IoCalendarOutline } from 'react-icons/io5'; // Import calendar icon
import SubtaskProgress from './SubtaskProgress';
import { MdOutlinePlaylistAddCheckCircle } from 'react-icons/md';
import { BsFillCalendar2CheckFill } from 'react-icons/bs';

// State to track selected subtask for calendar popup

const apiUrl = process.env.REACT_APP_API_URL;

const GroupCyclesReview = () => {
  const navigate = useNavigate();
  const { cycleId, groupId } = useParams(); // Assuming cycleId is passed as a URL parameter
  const [cycle, setCycle] = useState(null);
  const [selectedSubtask, setSelectedSubtask] = useState(null);
  const [checkedSubtask, setCheckedSubtask] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);

  const [subtaskChanges, setSubtaskChanges] = useState({});

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success'); // 'success' or 'danger'

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [activeKey, setActiveKey] = useState(null); // State to handle the active accordion item
  const [confettiOpacity, setConfettiOpacity] = useState(1);
  const [groupDetails, setGroupDetails] = useState();
  const [totalProgress, setTotalProgress] = useState();
  const [selectedDates, setSelectedDates] = useState({});
  const [subtaskData, setSubtaskData] = useState({});
  const [subtaskProgress, setSubtaskProgress] = useState({});
  const [totalGoalProgress, setTotalGoalProgress] = useState({});
  useEffect(() => {
    setHasUnsavedChanges(Object.keys(subtaskChanges).length > 0);
  }, [subtaskChanges]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        const message =
          'You have unsaved changes. Are you sure you want to leave?';
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  // Function to navigate back
  const navigateBack = () => {
    //navigate(-"1");

    if (hasUnsavedChanges) {
      if (
        window.confirm(
          'You have unsaved changes. Do you want to save them before leaving?',
        )
      ) {
        handleSaveChanges().then(() => navigate(-1));
      } else if (
        window.confirm('Are you sure you want to leave without saving?')
      ) {
        navigate(-1);
      }
    } else {
      navigate(-1);
    }
  };
  const getCurrentSubtaskProgress = (subtask) => {
    return subtaskProgress[subtask._id] || 0;
  };

  //calculateTotalProgress
  const calculateTotalProgress = (goal) => {
    const dailysubtasks = goal.subtasks.filter(
      (st) => st.frequency === 'daily',
    );

    const oneOffSubtasks = goal.subtasks.filter(
      (st) => st.frequency === 'one-off',
    );

    const totalSubtasks = goal.subtasks.length;

    const totalOneOffCompleted = oneOffSubtasks.filter(
      (ofs) => ofs.isCompleted === true,
    );

    // console.log('ddd', checkIfDaily);
    //const subtasks = goal.subtasks;

    const totalDaily = dailysubtasks.map((e) => calculateSubtaskProgress(e));
    const totalDailySum = totalDaily.reduce(
      (partialSum, a) => partialSum + Number(a),
      0,
    );

    const totalDailySumWholeNumber = totalDailySum / 100;

    const sumProgress =
      ((totalDailySumWholeNumber + totalOneOffCompleted.length) /
        totalSubtasks) *
      100;

    return sumProgress.toFixed(2);
  };
  // Toast display function
  const displayToast = (message, type) => {
    setToastMessage(message);
    setToastType(type);
    setShowToast(true);
  };

  const handleSubtaskChange = (
    cyclememberId,
    goalId,
    subtaskId,
    isCompleted,
  ) => {
    const key = `${cyclememberId}-${goalId}-${subtaskId}`;
    setSubtaskChanges((prev) => ({
      ...prev,
      [key]: !isCompleted, // Toggle the current state
    }));

    // Trigger confetti when a subtask is completed
    if (!isCompleted) {
      setShowConfetti(true);
      setConfettiOpacity(1); // Reset opacity to full when showing confetti
      setTimeout(() => {
        setConfettiOpacity(0); // Start fading out after 5 seconds
        setTimeout(() => setShowConfetti(false), 500); // Hide confetti completely after fade
      }, 2000);
    }

    // Optimistically update the cycle state
    setCycle((prevCycle) => {
      return {
        ...prevCycle,
        cycleMembers: prevCycle.cycleMembers.map((cyclemember) => {
          if (cyclemember._id === cyclememberId) {
            return {
              ...cyclemember,
              goals: cyclemember.goals.map((goal) => {
                if (goal._id === goalId) {
                  return {
                    ...goal,
                    subtasks: goal.subtasks.map((subtask) => {
                      if (subtask._id === subtaskId) {
                        return { ...subtask, isCompleted: !isCompleted };
                      }
                      return subtask;
                    }),
                  };
                }
                return goal;
              }),
            };
          }
          return cyclemember;
        }),
      };
    });

    //setSelectedSubtask({ cyclememberId, goalId, subtaskId });
    setCheckedSubtask({ cyclememberId, goalId, subtaskId });
    // handleSaveChanges();
  };

  const handleSaveChanges = async () => {
    const updates = Object.entries(subtaskChanges).map(([key, isCompleted]) => {
      const [cyclememberId, goalId, subtaskId] = key.split('-');
      // console.log("")
      return axios
        .patch(`${apiUrl}/cycles/${cycleId}/subtasks/toggle`, {
          cyclememberId,
          goalId,
          subtaskId,
        })
        .catch((error) => {
          console.error('Error updating subtask:', error);
          displayToast('Error updating subtasks!', 'danger');
        });
    });

    Promise.all(updates)
      .then(() => {
        displayToast('All changes saved successfully!', 'success');
        setSubtaskChanges({});
      })
      .catch((error) => {
        console.error('Error saving all changes:', error);
        displayToast('Failed to save some changes!', 'danger');
      });
  };

  // const saveBeforePopup = async () => {
  //   const updates = Object.entries(subtaskChanges).map(([key, isCompleted]) => {
  //     const [cyclememberId, goalId, subtaskId] = key.split('-');
  //     return axios
  //       .patch(`${apiUrl}/cycles/${cycleId}/subtasks/toggle`, {
  //         cyclememberId,
  //         goalId,
  //         subtaskId,
  //       })
  //       .catch((error) => {
  //         console.error('Error updating subtask:', error);
  //         displayToast('Error updating subtasks!', 'danger');
  //       });
  //   });

  //   Promise.all(updates)
  //     .then(() => {
  //       // displayToast('All changes saved successfully!', 'success');
  //       setSubtaskChanges({});
  //     })
  //     .catch((error) => {
  //       // console.error('Error saving all changes:', error);
  //       //displayToast('Failed to save some changes!', 'danger');
  //     });
  // };

  function formatDateWithOrdinal(date) {
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const suffix = getDaySuffix(day);
    return `${day}${suffix} ${month}`;
  }

  function getDaySuffix(day) {
    if (day >= 11 && day <= 13) {
      return 'th';
    }
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

  // Function to create subtask progress object from an array of objects
  const createSubtaskProgressObjectFromArray = (dataArray) => {
    const subtaskProgressObject = {};

    // Iterate through each object in the array
    dataArray.forEach((data) => {
      // Iterate through goals
      data.goals.forEach((goal) => {
        // Iterate through subtasks
        goal.subtasks.forEach((subtask) => {
          // Check if subtask has subtaskProgress defined
          if (subtask.subtaskProgress !== undefined) {
            // Add subtask ID and progress to object
            subtaskProgressObject[subtask._id] =
              subtask.subtaskProgress.toFixed(2);
          }
        });
      });
    });

    return subtaskProgressObject;
  };

  // const createGoalProgressObject = (data) => {
  //   cons
  //   const goalProgressObject = {};
  //   data.forEach((data) => {
  //     data.goals.forEach((goal) => {
  //       goalProgressObject[goal._id] = calculateTotalProgress(goal);
  //     });
  //   });
  //   return goalProgressObject;
  // };
  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem('token'); //
    const isLoggedIn = Boolean(localStorage.getItem('token'));
    const fetchCycle = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${apiUrl}/cycles/${groupId}/${cycleId}`,
          {
            headers: {
              'x-auth-token': token,
            },
          },
        );

        setCycle(response.data);

        const subtaskProgressObject = createSubtaskProgressObjectFromArray(
          response.data.cycleMembers,
        );

        setSubtaskProgress(subtaskProgressObject);
        //  setTotalGoalProgress(goalProgressObject);

        // Check if adminId is defined
        const response_gd = await axios.get(`${apiUrl}/groups/${groupId}`, {
          headers: {
            'x-auth-token': token,
          },
        });

        const group = response_gd.data;

        setGroupDetails(group);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch data');
        setLoading(false);
        console.error(err);
      }
    };

    fetchCycle();
    if (cycle) {
      //console.log('cycle: ', cycle);
      //const subtaskProgresss = cycle.map((cm) => cm.goals);
    }
    //calculateProgress();
  }, [cycleId, groupId]);

  //handleCalendarClick
  const handleCalendarClick = (cyclemember, goal, subtask) => {
    // console.log('handle calendar');
    // handleSaveChanges();
    setSelectedSubtask({
      cyclememberId: cyclemember._id,
      goalId: goal._id,
      subtaskId: subtask._id,
      stname: subtask.subTaskName,
    });
  }; // Function to toggle accordion item
  const handleToggle = (key) => {
    // If the key is already active, set it to null (collapse), otherwise set to the clicked key
    setActiveKey(activeKey === key ? null : key);
  };

  // if (loading) return <Spinner animation="border" />;
  // if (error) return <p>Error loading the data!</p>;
  // if (!cycle) return <p>No data found!</p>;
  const formatCyclePeriod = () => {
    const startDate = new Date(cycle.startDate);
    const endDate = new Date(cycle.endDate);

    const formattedStartDate = formatDateWithOrdinal(startDate);
    const formattedEndDate = formatDateWithOrdinal(endDate);

    return ` ${formattedStartDate} - ${formattedEndDate}`;
  };
  const calculateSubtaskProgress = (subtask) => {
    // const startDate = new Date(cycle.startDate);
    // const endDate = new Date(subtask.endDate);
    if (cycle) {
      const startDate = new Date(cycle.startDate);
      const endDate = new Date(cycle.endDate);
      //  console.log('subtask');

      //console.log(JSON.stringify(subtask));
      // Calculate total days between start and end date (inclusive)

      if (subtask.completedDays) {
        const totalDays =
          Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;

        // Count completed days that fall within the range
        // const completedDays = subtask.completedDays.filter((date) => {
        //   const currentDate = new Date(date);
        //   return currentDate >= startDate && currentDate <= endDate;
        // }).length;

        //  console.log();
        // Calculate progress percentage
        const progress =
          totalDays > 0 ? (subtask.completedDays.length / totalDays) * 100 : 0;

        return progress.toFixed(2); // Return progress percentage rounded to two decimal places
      }
    }
  };

  const getCurrentTotalProgress = (goal) => {
    return totalGoalProgress[goal._id] || 0;
  };
  const handleSubtaskSave = (data) => {
    //console.log(`Subtask ${subtaskId} saved`);
    // setSelectedSubtask(null); // Reset selectedSubtask
    // calculateProgress(data.subtask._id);
    // setSelectedSubtask(null);
    // Additional logic can be added here if needed
  };

  const calculateGoalProgress = () => {};
  const handleSaveDates = (cyclememberId, goalId, subtaskId, dates) => {
    // Update the state with selected dates
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [subtaskId]: dates,
    }));
    //console.log("cyclememberId, goalId, subtaskId, dates ", )
    // Recalculate the progress
    // Assuming you have a function to calculate the progress based on dates
    recalculateSubtaskProgress(cyclememberId, goalId, subtaskId, dates);

    // calculateGoalProgress(goalId, subtaskId);
    // handleSubtaskChange(
    //   cyclememberId,
    //   goalId,
    //   subtaskId,
    //   subtaskId.isCompleted,
    // );
    calculateTotalProgress(goalId);
    // console.log('');
  };

  const recalculateSubtaskProgress = (
    cyclememberId,
    goalId,
    subtaskId,
    dates,
  ) => {
    // Logic to recalculate the progress based on the dates
    const startDate = new Date(cycle.startDate);
    const endDate = new Date(cycle.endDate);
    const totalDays =
      Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;
    const completedDays = dates.length;
    const progressPercentage = ((completedDays / totalDays) * 100).toFixed(2);

    setSubtaskProgress((prevProgress) => ({
      ...prevProgress,
      [subtaskId]: progressPercentage,
    }));
    // Update the relevant subtask's progress in state
  };

  return (
    <>
      <Row className="main-container">
        <Col md={2} className="sidebar">
          <SideNav />
        </Col>
        <Col className="review-section">
          {loading ? (
            <div className="d-flex justify-content-center align-items-center vh-75">
              <div className="loading-section">
                <Watch
                  visible={true}
                  height="40"
                  width="40"
                  radius="48"
                  color="#4fa94d"
                  ariaLabel="watch-loading"
                  wrapperStyle={{}}
                  wrapperClass="watch-load"
                />
                <p className="loading-text">
                  "The two most powerful warriors are patience and time." – Leo
                  Tolstoy
                </p>
              </div>
            </div>
          ) : (
            <div>
              <Helmet>
                <title>Review Cycle</title>
                <meta
                  name="description"
                  content="Asquad - accountability made easy"
                />
              </Helmet>

              <Toast
                onClose={() => setShowToast(false)}
                show={showToast}
                delay={3000}
                autohide
                bg={toastType === 'success' ? 'success' : 'danger'}
                style={{
                  position: 'fixed',
                  bottom: 70,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  zIndex: 9999,
                  color: 'white ',
                }}
              >
                <Toast.Body>{toastMessage}</Toast.Body>
              </Toast>
              <MainLayout>
                <div className="review-header">
                  <p>{groupDetails && groupDetails.groupname}</p>
                </div>
                <div className="period-section-header">
                  <div className="icon-calendar">
                    <LuCalendarClock />
                  </div>

                  <p className="period-title">{formatCyclePeriod(cycle)}</p>
                </div>
                {showConfetti && (
                  <div
                    style={{
                      opacity: confettiOpacity,
                      transition: 'opacity 0.5s ease-in-out',
                    }}
                  >
                    <Confetti width={window.width} height={window.height} />
                  </div>
                )}
                <div className="content">
                  <div className="accordion-container">
                    <Accordion activeKey={activeKey}>
                      {cycle.cycleMembers.map((cyclemember) => (
                        <Accordion.Item
                          eventKey={cyclemember._id}
                          key={cyclemember._id}
                        >
                          <Accordion.Header
                            onClick={() => handleToggle(cyclemember._id)}
                          >
                            {' '}
                            <IoPerson />
                            <div className="username-text">
                              {' '}
                              {cyclemember.user.name}{' '}
                            </div>{' '}
                          </Accordion.Header>

                          <Accordion.Body>
                            {cyclemember.goals.map((goal) => (
                              <div className="goal-wrapper" key={goal._id}>
                                <Row>
                                  <Col>
                                    <p className="main-goal title">
                                      {goal.goalName}
                                    </p>
                                  </Col>
                                  <Col>
                                    <ProgressBar
                                      variant="success" // Optional: Change the color variant as needed
                                      now={calculateTotalProgress(goal)}
                                      className="custom-progress-bar"
                                      label={
                                        <span>{`${calculateTotalProgress(
                                          goal,
                                        )}%`}</span>
                                      }
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <ListGroup>
                                      {goal.subtasks.map((subtask) => (
                                        <ListGroup.Item key={subtask._id}>
                                          <div className="subtask-wrapper">
                                            <div className="subtask-name">
                                              {subtask.frequency ===
                                              'one-off' ? (
                                                <Form.Check
                                                  type="checkbox"
                                                  checked={subtask.isCompleted}
                                                  onChange={() =>
                                                    handleSubtaskChange(
                                                      cyclemember._id,
                                                      goal._id,
                                                      subtask._id,
                                                      subtask.isCompleted,
                                                    )
                                                  }
                                                  label={subtask.subTaskName}
                                                />
                                              ) : (
                                                <p className="daily-subtask-name">
                                                  {subtask.subTaskName}
                                                </p>
                                              )}
                                            </div>
                                            {/* Progress Bar */}
                                            {subtask.frequency === 'daily' && (
                                              <>
                                                <div className="progress-div">
                                                  <ProgressBar
                                                    variant="success" // Optional: Change the color variant as needed
                                                    now={getCurrentSubtaskProgress(
                                                      subtask,
                                                    )}
                                                    label={
                                                      <span>
                                                        {`${getCurrentSubtaskProgress(
                                                          subtask,
                                                        )}%`}{' '}
                                                      </span>
                                                    }
                                                    className="custom-progress-bar"
                                                  />
                                                </div>
                                                <div className="mark-off-section">
                                                  <BsFillCalendar2CheckFill
                                                    className="calendar-icon"
                                                    onClick={() =>
                                                      handleCalendarClick(
                                                        cyclemember,
                                                        goal,
                                                        subtask,
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </ListGroup.Item>
                                      ))}
                                    </ListGroup>
                                  </Col>
                                </Row>
                              </div>
                            ))}
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </div>
                </div>
                {selectedSubtask && (
                  <CalendarPopup
                    show={selectedSubtask !== null}
                    onHide={() => {
                      setSelectedSubtask(null);
                    }}
                    cyclememberId={selectedSubtask.cyclememberId}
                    goalId={selectedSubtask.goalId}
                    subtaskId={selectedSubtask.subtaskId}
                    cycleId={cycleId}
                    startDate={new Date(cycle.startDate)}
                    endDate={new Date(cycle.endDate)}
                    onSave={handleSaveDates}
                    subtaskName={selectedSubtask.stname}
                  />
                )}
                <div style={{ marginTop: '20px' }}>
                  <FloatingGoBackButton onClick={navigateBack} />
                  <FloatingSaveButton onClick={handleSaveChanges} />
                </div>
              </MainLayout>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default GroupCyclesReview;
