import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './CalendarPopup.css'; // Custom CSS for crossed-out effect
import axios from 'axios';
import { IoIosClose } from 'react-icons/io';

const apiUrl = process.env.REACT_APP_API_URL;

const CalendarPopup = ({
  show,
  onHide,
  cyclememberId,
  goalId,
  subtaskId,
  cycleId,
  startDate,
  endDate,
  onSave,
  subtaskName,
}) => {
  // State to track selected dates for the subtask
  const [selectedDates, setSelectedDates] = useState([]);
  const [completedDates, setCompletedDates] = useState([]);

  // Function to handle click on a date in the calendar
  const handleDateClick = (date) => {
    const dateIndex = selectedDates.findIndex(
      (d) => d.getTime() === date.getTime(),
    );

    if (dateIndex === -1) {
      // Add the date to the selected dates
      setSelectedDates([...selectedDates, date]);
    } else {
      // Remove the date from the selected dates
      const updatedDates = [...selectedDates];
      updatedDates.splice(dateIndex, 1);
      setSelectedDates(updatedDates);
    }
  };

  function normalizeDate(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  // Function to determine if a date should be disabled
  function tileDisabled({ date }) {
    // Normalize the startDate, endDate, and the input date to midnight
    const normalizedDate = normalizeDate(date);
    const start = normalizeDate(new Date(startDate));
    const end = normalizeDate(new Date(endDate));

    // Check if the given date is before the start date
    const isBeforeStart = normalizedDate < start;

    // Check if the given date is after the end date
    const isAfterEnd = normalizedDate > end;

    // Return true if the date is outside the range, otherwise false
    if (isBeforeStart || isAfterEnd) {
      return true; // Date is disabled
    } else {
      return false; // Date is enabled
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('token');

    const fetchCompletedDates = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/cycles/${cycleId}/${cyclememberId}/${goalId}/${subtaskId}/completed-days`,
          {
            headers: {
              'x-auth-token': token,
            },
          },
        );

        // const completedDates = response.data;
        ///console.log(completedDates);

        setSelectedDates(response.data.map((date) => new Date(date)));
      } catch (error) {
        console.log(error);
      }
    };

    fetchCompletedDates();
    if (completedDates.length > 0) {
      //console.log('completedDates', completedDates);
    }
  }, [subtaskId]);
  // Function to handle saving the selected dates
  const handleSaveDates = async () => {
    // Log the selected dates and subtask details
    const token = localStorage.getItem('token'); //
    try {
      const response = await axios.post(
        `${apiUrl}/cycles/${cycleId}/${cyclememberId}/${goalId}/${subtaskId}`,
        { selectedDates },
        {
          headers: {
            'x-auth-token': token,
          },
        },
      );

      if (response.data.success) {
        onSave(cyclememberId, goalId, subtaskId, selectedDates);
      }
    } catch (error) {
      console.log(error);
    }

    // console.log(
    //   `CycleID: ${cycleId}, CycleMember ID: ${cyclememberId}, Goal ID: ${goalId}, Subtask ID: ${subtaskId}, Selected Dates:`,
    //   selectedDates,
    // );

    // Close the modal

    onHide();
  };

  return (
    <Modal className="calendar-modal" show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>{subtaskName}</Modal.Title>
        {/* <button
          type="button"
          className="btn-close custom-close-button"
          aria-label="Close"
          onClick={onHide}
        ></button> */}
        <div onClick={onHide} className="custom-close-button">
          <IoIosClose />
        </div>
      </Modal.Header>
      <Modal.Body>
        {/* Calendar component */}
        <Calendar
          className="custom-calendar"
          onClickDay={handleDateClick} // Handle click on a date
          tileClassName={
            ({ date }) =>
              selectedDates.find((d) => d.getTime() === date.getTime())
                ? 'selected'
                : null // Apply custom style to selected dates
          }
          tileDisabled={tileDisabled} // Disable dates function
        />
        {/* Button to save selected dates */}
        <Button
          variant="primary"
          className="save-dates"
          onClick={handleSaveDates}
        >
          save
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default CalendarPopup;
