import logo from './logo.svg';
import './App.css';
import { useEffect } from 'react';
import Overview from './components/Overview';
import WelcomeUser from './screens/WelcomeUser';
import OurNavbar from './components/OurNavbar';
import AcceptInvitePage from './screens/AcceptInvitePage';
import { GroupProvider } from './components/GroupContext';
import UpdateCycle from './components/UpdateCycle';
import { AdminProvider } from './components/AdminContext';
import { UserProvider } from './components/UserContext';
import { createBrowserHistory } from 'history';
import SignUp from './components/SignUpForm';
import { GoogleLogin } from '@react-oauth/google';
import Login from './components/LoginForm';
import LandingNav from './components/LandingNav';
import LandingPage from './components/LandingPage';
import ResetPassword from './components/ResetPassword';
import BrowseGroups from './screens/BrowseGroups';
import ChangePassword from './components/ChangePassword';
import { GoogleOAuthProvider } from '@react-oauth/google';
import CreateGroup from './screens/CreateGroup';
import CreateCycleGroup from './screens/CreateCycleGroup';
import MyGroups from './screens/MyGroups';
import GridCarousel from './components/GridCarousel';
import MyGroup from './screens/MyGroup';
import GroupCycleOverview from './screens/GroupCycleOverview';
import GroupCyclesReview from './components/GroupCyclesReview';
import Dashboard from './screens/Dashboard';
import { PlaceholderAnimation } from 'react-bootstrap/esm/usePlaceholder';

import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
  Redirect,
  Navigate,
} from 'react-router-dom';
import GroupDetails from './screens/GroupDetails';
import AddMember from './screens/AddMember';
import PlanSelectionPage from './components/PlanSelectionPage';
import InjectedCheckoutForm from './components/InjectedCheckoutForm';
import SubscribeSuccess from './screens/SubscribeSuccess';
import ReactGA from 'react-ga4';
import NewMyGroup from './screens/NewMyGroup';
import BookSlick from './components/BookSlick';

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const TRACKING_ID = 'G-LNEW7TPFJ6';
ReactGA.initialize(TRACKING_ID);

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    });
  }, [location]);
};

function AppRouter() {
  const navigate = useNavigate();
  // const location = useLocation();
  const isLoggedIn = Boolean(localStorage.getItem('token'));

  function handleCallbackResponse(response) {
    // console.log('encoded JWT ID token: ' + response.credential);
  }

  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          isLoggedIn ? (
            <Navigate to="/dashboard" replace />
          ) : (
            <Navigate to="/welcome" replace />
          )
        }
      />
      <Route path="mygroups/:groupId/addmember" element={<AddMember />} />
      <Route path="/plans" element={<PlanSelectionPage />} />
      <Route path="checkout" element={<InjectedCheckoutForm />} />
      {/* <Route path = '/subscribe-success'  element={<SubscribeSuccess/>}/> */}
      <Route path="/welcome" element={<LandingPage />} />

      <Route path="/creategroup" element={<CreateGroup />} />
      <Route
        path="/accept-invite/:invite_token"
        element={<AcceptInvitePage />}
      />
      <Route path="/browsegroups" element={<BrowseGroups />} />
      <Route path="/mygroups" element={<MyGroups />} />
      <Route path="/mygroups/:groupId/currentcycles" element={<Overview />} />
      <Route path="/userwelcome" element={<WelcomeUser />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/mygroups/:groupId" element={<NewMyGroup />} />
      <Route
        path="/mygroups/:groupId/groupcycle"
        element={<CreateCycleGroup />}
      />
      <Route
        path="/mygroups/:groupId/groupcycleoverview"
        element={<GroupCycleOverview />}
      />
      <Route
        path="/mygroups/:groupId/groupcycleoverview/review/:cycleId"
        element={<GroupCyclesReview />}
      />
      <Route path="/browsegroups/:groupId" element={<GroupDetails />} />
      {/* <Route path="/mygroups/:groupId/create" element={<CreateCycle />} /> */}
      <Route path="/update/:groupId/:cycleId" element={<UpdateCycle />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/login" element={<Login />} />
      <Route path="/reset" element={<ResetPassword />} />
      <Route path="/" element={<ResetPassword />} />

      <Route path="/changepwd/:id/:token" element={<ChangePassword />} />
    </Routes>
  );
}

function App() {
  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <Router>
        <UserProvider>
          <GroupProvider>
            <OurNavbar />
            <AppRouter />
            <PageTracker />
          </GroupProvider>
        </UserProvider>
      </Router>
    </GoogleOAuthProvider>
  );
}

function PageTracker() {
  usePageTracking();
  return null;
}

export default App;
