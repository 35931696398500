import React, { useEffect, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';

const calculateProgress = (subtask, startDate, endDate) => {
  if (startDate && endDate) {
    const totalDays =
      Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;
    const progress =
      totalDays > 0 ? (subtask.completedDays.length / totalDays) * 100 : 0;
    return progress.toFixed(2);
  }
  return 0;
};

const SubtaskProgress = ({ subtask, startDate, endDate, selectedSubtask }) => {
  const [progress, setProgress] = useState(0);

  // useEffect(() => {
  //   const token = localStorage.getItem('token');
  //   const fetchSubtaskProgress = async () => {
  //     //   setLoading(true);
  //     try {
  //       const response = await axios.get(
  //         `${apiUrl}/cycles/${cycleId}/${cyclememberId}/${goalId}/${subtaskId}`,
  //         {
  //           headers: {
  //             'x-auth-token': token,
  //           },
  //         },
  //       );

  //       setCycle(response.data);

  //       // setLoading(false);
  //     } catch (err) {
  //       setError('Failed to fetch data');
  //       setLoading(false);
  //       console.error(err);
  //     }
  //   };

  //   fetchSubtaskProgress();
  //   if (cycle) {
  //     console.log('cycle: ', cycle);
  //   }
  // }, [selectedSubtask]);

  return (
    <ProgressBar variant="success" now={progress} label={`${progress}%`} />
  );
};

export default SubtaskProgress;
