import React, { Component, useEffect, useState, useContext } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { AdminContext } from '../components/AdminContext';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import Label from 'react-bootstrap/FormLabel';
import { Alert, Toast, ToastContainer } from 'react-bootstrap';
import MainLayout from '../components/MainLayout';
import { Helmet } from 'react-helmet-async';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { IoIosArrowRoundBack } from 'react-icons/io';
import Modal from 'react-bootstrap/Modal';
import FloatingGoBackButton from '../components/FloatingGoBackButton';
import FloatingSaveButton from '../components/FloatingSaveButton';
import SearchableDropdown from './SearchableDropdown';
import { Watch } from 'react-loader-spinner';

import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import SideNav from '../components/SideNav';
import './MaterialDesign.css';
const apiUrl = process.env.REACT_APP_API_URL;

const CreateCycleGroup = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const navigate = useNavigate();
  const { groupId } = useParams();
  const [emailNotifications, setEmailNotifications] = useState(true);
  const [loading, setLoading] = useState(true);

  // const handleEmailNotifications = (event) => {
  //   console.log('Checkbox checked:', event.target.checked); // Debugging line
  //   setEmailNotifications(event.target.checked);
  // };

  useEffect(() => {
    setFormState((prevState) => ({
      ...prevState,
      emailNotifications: emailNotifications,
    }));
  }, [emailNotifications]);

  const handleEmailNotifications = (event) => {
    console.log('Checkbox checked:', event.target.checked);
    setEmailNotifications(event.target.checked);
  };

  // useEffect(() => {
  //   console.log('Email notifications state:', emailNotifications); // Debugging line
  // }, [emailNotifications]); //

  const [formState, setFormState] = useState({
    startDate: '',
    endDate: '',
    group: groupId,
    cycleMembers: [],
    emailNotifications: emailNotifications,
  });
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const [users, setUsers] = useState([]);
  const [userNames, setUsernames] = useState([]);

  const [dateError, setDateError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [groupDetails, setGroupDetails] = useState();

  useEffect(() => {
    const token = localStorage.getItem('token'); //
    const isLoggedIn = Boolean(localStorage.getItem('token'));

    const fetchUsers = async () => {
      try {
        // if(isLoggedIn){
        const response = await axios.get(`${apiUrl}/groups/${groupId}/users`, {
          headers: {
            'x-auth-token': token,
          },
        });

        setUsernames(response.data.map((user) => user.name));
        setUsers(response.data);

        if (groupId && isLoggedIn) {
          // Check if adminId is defined
          const response_gd = await axios.get(`${apiUrl}/groups/${groupId}`, {
            headers: {
              'x-auth-token': token,
            },
          });

          const group = response_gd.data;

          setGroupDetails(group);
          setLoading(false);
        }
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        const message =
          'You have unsaved changes. Are you sure you want to leave?';
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  const [startDate, setStartDate] = useState(); // empty startDate set when we start initially
  const [endDate, setEndDate] = useState(); // empty endDate set when we start initially

  const [selectedUsers, setSelectedUsers] = useState([]);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');

  const handleShowToast = (message, toastType) => {
    setToastMessage(message);
    setToastType(toastType);
    setShowToast(true);
    // Toast shows for 3 seconds
  };

  /*Delete stuff */

  const deleteGoal = (userId, goalIndex) => {
    const updatedMembers = formState.cycleMembers.map((member) =>
      member.user === userId
        ? {
            ...member,
            goals: member.goals.filter((_, index) => index !== goalIndex),
          }
        : member,
    );
    setFormState((prev) => ({ ...prev, cycleMembers: updatedMembers }));
    setHasUnsavedChanges(true);
  };

  const deleteSubtask = (userId, goalIndex, subtaskIndex) => {
    const updatedMembers = formState.cycleMembers.map((member) =>
      member.user === userId
        ? {
            ...member,
            goals: member.goals.map((goal, gIndex) =>
              gIndex === goalIndex
                ? {
                    ...goal,
                    subtasks: goal.subtasks.filter(
                      (_, sIndex) => sIndex !== subtaskIndex,
                    ),
                  }
                : goal,
            ),
          }
        : member,
    );
    setFormState((prev) => ({ ...prev, cycleMembers: updatedMembers }));
    setHasUnsavedChanges(true);
  };

  const handleSelectUser = (selectedUserName) => {
    const user = users.find((u) => u.name === selectedUserName);
    if (
      user &&
      !formState.cycleMembers.some((member) => member.user === user._id)
    ) {
      const newMember = {
        user: user._id,
        goals: [],
      };
      setFormState((prev) => ({
        ...prev,
        cycleMembers: [...prev.cycleMembers, newMember],
      }));
    }
    setHasUnsavedChanges(true);
  };

  /**TRIAL */

  // Adding goals and subtasks methods as before
  const addGoal = (userId) => {
    const updatedMembers = formState.cycleMembers.map((member) =>
      member.user === userId
        ? {
            ...member,
            goals: [...member.goals, { goalName: '', subtasks: [] }],
          }
        : member,
    );
    setFormState((prev) => ({ ...prev, cycleMembers: updatedMembers }));
    setHasUnsavedChanges(true);
  };

  // const addSubtask = (userId, goalIndex) => {
  //   const updatedMembers = formState.cycleMembers.map(member =>
  //     member.user === userId ? {
  //       ...member,
  //       goals: member.goals.map((goal, index) =>
  //         index === goalIndex ? { ...goal, subtasks: [...goal.subtasks, { subTaskName: "", isCompleted: false }] } : goal
  //       )
  //     } : member
  //   );
  //   setFormState(prev => ({ ...prev, cycleMembers: updatedMembers }));
  //   setHasUnsavedChanges(true);
  // };

  const addSubtask = (userId, goalIndex) => {
    const updatedMembers = formState.cycleMembers.map((member) =>
      member.user === userId
        ? {
            ...member,
            goals: member.goals.map((goal, index) =>
              index === goalIndex
                ? {
                    ...goal,
                    subtasks: [
                      ...goal.subtasks,
                      { subTaskName: '', isCompleted: false, isDaily: false },
                    ],
                  }
                : goal,
            ),
          }
        : member,
    );
    setFormState((prev) => ({ ...prev, cycleMembers: updatedMembers }));
    setHasUnsavedChanges(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('formState: ', formState);
    let hasEmptyGoals = false;

    const updatedCycleMembers = formState.cycleMembers.map((member) => {
      const updatedGoals = member.goals.map((goal) => {
        if (goal.subtasks.length === 0) {
          hasEmptyGoals = true;
          return {
            ...goal,
            subtasks: [{ subTaskName: '', isCompleted: false }],
          };
        }
        return goal;
      });
      return { ...member, goals: updatedGoals };
    });

    if (hasEmptyGoals) {
      setFormState((prev) => ({ ...prev, cycleMembers: updatedCycleMembers }));
      handleShowToast(
        'Each goal must have at least one subtask. Empty subtasks have been added.',
        'warning',
      );
      return;
    }

    if (!dateError) {
      const token = localStorage.getItem('token');
      try {
        await axios.post(`${apiUrl}/cycles`, formState, {
          headers: { 'x-auth-token': token },
        });
        handleShowToast('Cycle Saved Successfully!', 'success');
        setHasUnsavedChanges(false);
        navigate('..');
      } catch (error) {
        console.error(error);
        let message = 'An error occurred';
        if (error.response?.data?.message.includes('Cycle limit')) {
          const maxCycles = error.response.data.message.match(/\d+/)[0];
          message = `Sorry, you've reached the maximum limit of ${maxCycles} cycles you can create. Please upgrade your plan :)`;
          setErrorMessage(message);
          setShowErrorModal(true);
        } else {
          handleShowToast('Unexpected Error!', 'danger');
        }
      }
    }
  };

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    if (formState.endDate && newStartDate > formState.endDate) {
      setDateError('Start date cannot be later than end date.');
    } else {
      setDateError('');
    }
    setFormState((prev) => ({ ...prev, startDate: newStartDate }));
    setHasUnsavedChanges(true);
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    if (formState.startDate && formState.startDate > newEndDate) {
      setDateError('End date cannot be earlier than start date.');
    } else {
      setDateError('');
    }
    setFormState((prev) => ({ ...prev, endDate: newEndDate }));
    setHasUnsavedChanges(true);
  };

  // Function to navigate back
  const navigateBack = () => {
    if (
      !hasUnsavedChanges ||
      window.confirm(
        'You have unsaved changes. Are you sure you want to leave?',
      )
    ) {
      navigate(-1);
    }
  };

  return (
    <>
      <Row>
        <Col md={2} className="sidebar">
          <SideNav />
        </Col>
        <Col md={10}>
          <Helmet>
            <title>Create Cycle</title>
            <meta
              name="description"
              content="Asquad - accountability made easy"
            />
          </Helmet>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center vh-75">
              <div className="loading-section">
                <Watch
                  visible={true}
                  height="40"
                  width="40"
                  radius="48"
                  color="#4fa94d"
                  ariaLabel="watch-loading"
                  wrapperStyle={{}}
                  wrapperClass="watch-load"
                />
                <p className="loading-text">
                  "The two most powerful warriors are patience and time." – Leo
                  Tolstoy
                </p>
              </div>
            </div>
          ) : (
            <Container>
              <MainLayout title="Create Cycle">
                <p className="h-gd-title">
                  {groupDetails && groupDetails.groupname}{' '}
                </p>

                <Toast
                  onClose={() => setShowToast(false)}
                  show={showToast}
                  delay={3000}
                  autohide
                  bg={toastType === 'success' ? 'success' : 'danger'}
                  style={{
                    position: 'fixed',
                    bottom: 70,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 9999,
                    color: 'white ',
                  }}
                >
                  <Toast.Body>{toastMessage}</Toast.Body>
                </Toast>

                <Form className="form-wrapper" onSubmit={handleSubmit}>
                  {/* Start and End Date Inputs */}

                  <Row className="justify-content-center mb-3">
                    <Col md={6} lg={3}>
                      <Form.Group>
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                          type="date"
                          name="startDate"
                          value={formState.startDate}
                          required="true"
                          onChange={handleStartDateChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} lg={3}>
                      <Form.Group>
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                          type="date"
                          name="endDate"
                          value={formState.endDate}
                          onChange={handleEndDateChange}
                          required="true"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* Searchable Dropdown centered under the date inputs */}
                  <Row className="justify-content-center mb-3">
                    <Col md={6} lg={6}>
                      <SearchableDropdown
                        options={userNames}
                        onSelect={handleSelectUser}
                      />
                    </Col>
                  </Row>

                  {formState.cycleMembers.map((member, mIndex) => (
                    <div key={mIndex} className="mb-3">
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Card.Title>
                          {users.find((u) => u._id === member.user)?.name}
                        </Card.Title>
                        <Button
                          className="add-goal-btn"
                          variant="primary"
                          onClick={() => addGoal(member.user)}
                        >
                          Add Goal
                        </Button>
                      </div>
                      {member.goals.map((goal, gIndex) => (
                        <div key={gIndex} className="mt-2">
                          <Card.Subtitle>
                            {`${
                              users.find((u) => u._id === member.user)?.name
                            }'s Goal #${gIndex + 1}`}
                          </Card.Subtitle>
                          <InputGroup className="mb-2">
                            <Form.Control
                              type="text"
                              value={goal.goalName}
                              onChange={(e) => {
                                const goalName = e.target.value;
                                setFormState((prev) => {
                                  const updatedCycleMembers =
                                    prev.cycleMembers.map((mem) =>
                                      mem.user === member.user
                                        ? {
                                            ...mem,
                                            goals: mem.goals.map((g, gi) =>
                                              gi === gIndex
                                                ? { ...g, goalName: goalName }
                                                : g,
                                            ),
                                          }
                                        : mem,
                                    );
                                  return {
                                    ...prev,
                                    cycleMembers: updatedCycleMembers,
                                  };
                                });
                              }}
                              placeholder="Goal Name"
                              className="goal-input"
                              required={true}
                            />
                            <Button
                              className="delete-goal-btn"
                              variant="danger"
                              onClick={() => deleteGoal(member.user, gIndex)}
                            >
                              X
                            </Button>
                          </InputGroup>
                          <Button
                            className="add-sbtask-btn"
                            variant="secondary"
                            onClick={() => addSubtask(member.user, gIndex)}
                          >
                            Add Subtask
                          </Button>

                          {goal.subtasks.map((subtask, sIndex) => (
                            <InputGroup className="mb-2" key={sIndex}>
                              <Form.Control
                                type="text"
                                value={subtask.subTaskName}
                                onChange={(e) => {
                                  const subTaskName = e.target.value;
                                  setFormState((prev) => {
                                    const updatedCycleMembers =
                                      prev.cycleMembers.map((mem) =>
                                        mem.user === member.user
                                          ? {
                                              ...mem,
                                              goals: mem.goals.map((g, gi) =>
                                                gi === gIndex
                                                  ? {
                                                      ...g,
                                                      subtasks: g.subtasks.map(
                                                        (s, si) =>
                                                          si === sIndex
                                                            ? {
                                                                ...s,
                                                                subTaskName:
                                                                  subTaskName,
                                                              }
                                                            : s,
                                                      ),
                                                    }
                                                  : g,
                                              ),
                                            }
                                          : mem,
                                      );
                                    return {
                                      ...prev,
                                      cycleMembers: updatedCycleMembers,
                                    };
                                  });
                                }}
                                placeholder="Subtask Name"
                                required={true}
                                className="subtask-input"
                              />

                              <Form.Check
                                type="checkbox"
                                checked={subtask.frequency === 'daily'} // Update checked based on subtask.frequency
                                onChange={(e) => {
                                  const isDaily = e.target.checked;
                                  console.log(e.target.checked);

                                  setFormState((prev) => {
                                    const updatedCycleMembers =
                                      prev.cycleMembers.map((mem) =>
                                        mem.user === member.user
                                          ? {
                                              ...mem,
                                              goals: mem.goals.map((g, gi) =>
                                                gi === gIndex
                                                  ? {
                                                      ...g,
                                                      subtasks: g.subtasks.map(
                                                        (s, si) =>
                                                          si === sIndex
                                                            ? {
                                                                ...s,
                                                                frequency:
                                                                  isDaily
                                                                    ? 'daily'
                                                                    : 'one-off', // Update frequency based on checkbox state
                                                              }
                                                            : s,
                                                      ),
                                                    }
                                                  : g,
                                              ),
                                            }
                                          : mem,
                                      );
                                    return {
                                      ...prev,
                                      cycleMembers: updatedCycleMembers,
                                    };
                                  });
                                }}
                                label="Daily Task"
                              />
                              <Button
                                variant="outline-danger"
                                onClick={() =>
                                  deleteSubtask(member.user, gIndex, sIndex)
                                }
                              >
                                X
                              </Button>
                            </InputGroup>
                          ))}
                        </div>
                      ))}
                    </div>
                  ))}
                  {formState.cycleMembers ? (
                    <div className="email-notifications">
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        label="Enable Email Notifications"
                        checked={emailNotifications}
                        onChange={handleEmailNotifications}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                  {dateError && <Alert variant="danger">{dateError}</Alert>}
                  <FloatingGoBackButton onClick={navigateBack} />
                  <FloatingSaveButton type="submit" />

                  {/* <Button className="create-cycle-btn" type="submit" >Submit Form</Button> */}
                </Form>
              </MainLayout>
            </Container>
          )}
        </Col>
      </Row>
      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowErrorModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateCycleGroup;
